import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RootPage from './components/RootPage'
import PageWithSubpages from './components/PageWithSubpages'

function BrowsePage(): JSX.Element {
	return (
		<Switch>
			<Route path="/browse/:path">
				<PageWithSubpages />
			</Route>
			<Route path="/" exact>
				<RootPage />
			</Route>
		</Switch>
	)
}

export default BrowsePage

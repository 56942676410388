import React from 'react'
import clsx from 'clsx'
import {
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import ListOfPages from './ListOfPages'
import {useSubpages} from '../db'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		margin: {
			marginBottom: theme.spacing(4),
		},
		marginSmaller: {
			marginBottom: theme.spacing(1),
		},
	})
)

interface Props {
	className?: string
}

function RootPage({className}: Props): JSX.Element {
	const classes = useStyles()
	const subpages = useSubpages('/pages')

	return (
		<Container maxWidth="md" className={clsx(classes.root, className)}>
			{subpages?.length === 0 ? (
				<Typography>No documents are shared with you.</Typography>
			) : (
				<>
					<Typography className={classes.marginSmaller} variant="h1">
						Creativedock reports
					</Typography>
					<Typography className={classes.margin}>
						Click on one of the items below to see the report
					</Typography>
					<ListOfPages hideTitle collectionPath="/pages" />
				</>
			)}
		</Container>
	)
}

export default RootPage

import React from 'react'
import clsx from 'clsx'
import {
	Button,
	createStyles,
	Divider,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {IframeContent} from '../../../models/Page'
import IframesListItem from './IframesListItem'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		leftSpace: {paddingLeft: theme.spacing(4)},
		addButton: {marginTop: theme.spacing(2)},
	})
)

interface Props {
	className?: string
	value: Array<IframeContent>
	onChange: (value: Array<IframeContent>) => void
}

function IframesListEdit({className, value, onChange}: Props): JSX.Element {
	const classes = useStyles()
	return (
		<div className={clsx(classes.root, className)}>
			<Typography>PowerBI iframes</Typography>
			<div className={classes.leftSpace}>
				{value.map((one, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<div key={i}>
						<IframesListItem
							value={one}
							onChange={(newValue) => {
								const newArray = value
								newArray[i] = newValue
								onChange(newArray)
							}}
							onCancel={() => {
								value.splice(i, 1)
								onChange(value)
							}}
						/>
						{i !== value.length - 1 && <Divider />}
					</div>
				))}
				<Button
					className={classes.addButton}
					onClick={() => {
						onChange([
							...value,
							{
								title: '',
								textMd: '',
								url: '',
							},
						])
					}}
					variant="outlined"
				>
					Add more
				</Button>
			</div>
		</div>
	)
}

export default IframesListEdit

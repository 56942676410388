import React from 'react'
import {createStyles, makeStyles, TextField} from '@material-ui/core'
import {Page} from '../../../models/Page'
import UsersListEdit from './UsersListEdit'
import IframesListEdit from './IframesListEdit'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		margin: {
			marginBottom: theme.spacing(2),
		},
	})
)

interface Props {
	className?: string
	data: Page
	onChange: (page: Page) => void
	onSubmit: () => void
}

function PageForm({className, data, onChange, onSubmit}: Props): JSX.Element {
	const classes = useStyles()
	return (
		<form className={`${classes.root} ${className}`} onSubmit={onSubmit}>
			<TextField
				fullWidth
				margin="normal"
				variant="outlined"
				label="Title"
				helperText="Will be displayed on page"
				value={data.title}
				onChange={(e) => onChange({...data, title: e.target.value})}
			/>
			<UsersListEdit
				className={classes.margin}
				data={{
					users: data.usersWithAccess,
					usersCanAccessAllSubpages: data.usersCanAccessAllSubpages,
				}}
				onChange={(newValue) => {
					onChange({
						...data,
						usersWithAccess: newValue.users,
						usersCanAccessAllSubpages: newValue.usersCanAccessAllSubpages,
					})
				}}
				title="Users with access"
			/>
			<IframesListEdit
				className={classes.margin}
				value={data.iframes}
				onChange={(value) => onChange({...data, iframes: value})}
			/>
		</form>
	)
}

export default PageForm

import React from 'react'
import {createMuiTheme, CssBaseline, MuiThemeProvider} from '@material-ui/core'
import {SnackbarProvider} from 'notistack'
import LoginProvider from './components/AuthProvider'
import InsidePage from './components/InsidePage'

const theme = createMuiTheme({
	palette: {
		primary: {main: '#FFD722'},
	},
	typography: {
		fontSize: 16,
		h1: {
			fontSize: '3.052rem',
		},
		h2: {
			fontSize: '2.441rem',
		},
		h3: {
			fontSize: '1.953rem',
		},
		h4: {
			fontSize: '1.563rem',
		},
	},
})

function App(): JSX.Element {
	return (
		<div className="App">
			<MuiThemeProvider theme={theme}>
				<SnackbarProvider>
					<CssBaseline />
					<LoginProvider>
						<InsidePage />
					</LoginProvider>
				</SnackbarProvider>
			</MuiThemeProvider>
		</div>
	)
}

export default App

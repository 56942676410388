import React from 'react'
import {
	AppBar,
	Button,
	createStyles,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {AccountCircle} from '@material-ui/icons'
import {useUser} from '../../AuthProvider'
import {auth} from '../../../firebase'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		title: {
			color: theme.palette.text.primary,
			textDecoration: 'none',
		},
		rootLink: {
			textDecoration: 'none',
		},
		grow: {
			flexGrow: 1,
		},
		appBar: {
			marginBottom: theme.spacing(4),
		},
	})
)

interface Props {
	className?: string
}

function TopBar({className}: Props): JSX.Element {
	const user = useUser()
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const isMenuOpen = Boolean(anchorEl)

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}
	const classes = useStyles()
	return (
		<AppBar
			className={`${className} ${classes.root}`}
			position="sticky"
			variant="outlined"
		>
			<Toolbar>
				<Link className={classes.rootLink} to="/">
					<Typography
						color="inherit"
						className={classes.title}
						variant="h6"
						noWrap
					>
						Reports
					</Typography>
				</Link>
				<div className={classes.grow} />
				{user.isAdmin && (
					<Button color="inherit" component={Link} to="/admin">
						Admin section
					</Button>
				)}
				<IconButton
					edge="end"
					aria-label="account of current user"
					aria-haspopup="true"
					onClick={handleProfileMenuOpen}
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					anchorOrigin={{vertical: 'top', horizontal: 'right'}}
					keepMounted
					transformOrigin={{vertical: 'top', horizontal: 'right'}}
					open={isMenuOpen}
					onClose={handleMenuClose}
				>
					<MenuItem disabled>
						<Typography>{user.email}</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleMenuClose()
							auth.signOut()
						}}
					>
						Logout
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	)
}

export default TopBar

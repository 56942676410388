import React, {useCallback, useState} from 'react'
import {
	Button,
	CircularProgress,
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import slugify from 'slugify'
import {useSnackbar} from 'notistack'
import {useAsync} from 'react-async'
import {useHistory, useParams} from 'react-router-dom'
import {Page} from '../../../models/Page'
import PageForm from './PageForm'
import {saveNewPage} from '../db'
import {getEmptyPage, isValidCDEmail} from '../utils'

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		submitButton: {},
		form: {
			width: '100%',
		},
	})
)

interface Props {
	className?: string
}

function CreatePage({className}: Props): JSX.Element {
	const classes = useStyles()
	const {enqueueSnackbar} = useSnackbar()
	const history = useHistory()

	const {parentPath: parentPathEncoded} = useParams<{parentPath?: string}>()

	const [data, setData] = useState<Page>(getEmptyPage())

	function setDataWithGeneratedId(newData: Page) {
		const id = data.title ? slugify(data.title) : ''
		setData({
			...newData,
			id,
		})
	}

	const submitPageTask = useAsync({
		deferFn: useCallback(async () => {
			if (
				data.usersWithAccess.filter(Boolean).some((one) => !isValidCDEmail(one))
			) {
				enqueueSnackbar(
					'Some of listed emails are not emails from creativedock domain.',
					{variant: 'error'}
				)
				return
			}

			try {
				const path = await (parentPathEncoded
					? saveNewPage(data, `/${decodeURIComponent(parentPathEncoded)}/pages`)
					: saveNewPage(data))

				setData(getEmptyPage())
				enqueueSnackbar('Page created', {variant: 'success'})
				history.push(`/browse/${encodeURIComponent(path)}`)
			} catch (e) {
				enqueueSnackbar(`Error while saving page: ${e.message}`, {
					variant: 'error',
				})
			}
		}, [data, setData, enqueueSnackbar, history, parentPathEncoded]),
	})

	return (
		<Container maxWidth="md" className={`${classes.root} ${className}`}>
			<Typography variant="h1">
				Create new{' '}
				{parentPathEncoded
					? `subpage to ${decodeURIComponent(parentPathEncoded).replace(
							'pages/',
							''
					  )}`
					: 'page'}
			</Typography>
			<PageForm
				className={classes.form}
				data={data}
				onChange={setDataWithGeneratedId}
				onSubmit={submitPageTask.run}
			/>
			<Button
				disabled={submitPageTask.isLoading}
				className={classes.submitButton}
				variant="contained"
				color="primary"
				onClick={submitPageTask.run}
			>
				{submitPageTask.isLoading ? <CircularProgress /> : 'Submit'}
			</Button>
		</Container>
	)
}

export default CreatePage

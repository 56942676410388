import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {
	Button,
	Container,
	createStyles,
	makeStyles,
	Modal,
	Typography,
} from '@material-ui/core'
import {detect as detectBrowser} from 'detect-browser'

function getModalStyle() {
	const top = 50
	const left = 50

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		paper: {
			position: 'absolute',
			width: '80vw',
			maxWidth: theme.spacing(60),
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	})
)

interface Props {
	className?: string
}

function SafariWarningOverlay({className}: Props): JSX.Element {
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)
	const [modalOpen, setModalOpen] = useState(false)

	useEffect(() => {
		const browser = detectBrowser()
		if (browser?.name === 'safari') setModalOpen(true)
	}, [setModalOpen])

	const body = (
		<Container style={modalStyle} className={classes.paper}>
			<Typography variant="h2">Please use different browser</Typography>
			<Typography>
				There are some known problems with viewing reports in Safari. Please use
				firefox/chrome to make sure everything displays correctly.
			</Typography>
			<Button onClick={() => setModalOpen(false)}>Display anyway</Button>
		</Container>
	)

	return (
		<Modal
			open={modalOpen}
			onClose={() => setModalOpen(false)}
			className={clsx(classes.root, className)}
		>
			{body}
		</Modal>
	)
}

export default SafariWarningOverlay

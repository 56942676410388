import React, {useMemo} from 'react'
import clsx from 'clsx'
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Button,
	createStyles,
	Divider,
	makeStyles,
	Typography,
} from '@material-ui/core'
import firebase from 'firebase'
import {ExpandMore} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {useObservable} from 'rxjs-hooks'
import {collection as collection$} from 'rxfire/firestore'
import {Page} from '../../../models/Page'
import {firestore} from '../../../firebase'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		container: {
			width: '100%',
			marginBottom: theme.spacing(1),
		},
		iframe: {
			width: '100%',
			height: theme.spacing(50),
			marginBottom: theme.spacing(2),
		},
	})
)

interface Props {
	className?: string
	pageDocument: firebase.firestore.QueryDocumentSnapshot
}

function PagePreview({className, pageDocument}: Props): JSX.Element {
	const classes = useStyles()

	const subpages = useObservable(() =>
		collection$(firestore.collection(`${pageDocument.ref.path}/pages`))
	)

	const pageData = useMemo<Page>(() => {
		return {
			...pageDocument.data(),
			id: pageDocument.id,
		} as Page
	}, [pageDocument])

	return (
		<Accordion className={clsx(classes.root, className)}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography variant="h4">{pageData.title}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div className={classes.container}>
					<div className={classes.container}>
						<Typography>
							<b>id:</b> {pageData.id}
						</Typography>
					</div>
					<div className={classes.container}>
						<Typography>
							<b>Users with access:</b>
						</Typography>
						<Typography>{pageData.usersWithAccess.join(', ')}</Typography>
						{pageData.usersCanAccessAllSubpages && (
							<Typography>
								Users can access all subpages automatically
							</Typography>
						)}
					</div>
					<div className={classes.container}>
						<Typography>
							<b>Iframes</b>
						</Typography>
						{pageData.iframes.map((oneIframe) => (
							<div key={oneIframe.url}>
								<Typography>{oneIframe.title}</Typography>
								<iframe
									className={classes.iframe}
									title={oneIframe.title}
									src={oneIframe.url}
									frameBorder="0"
								/>
							</div>
						))}
					</div>
					{subpages && subpages.length > 0 && (
						<>
							<Typography>
								<b>Subpages:</b>
							</Typography>
							{subpages.map((one) => (
								<PagePreview key={one.ref.path} pageDocument={one} />
							))}
						</>
					)}
				</div>
			</AccordionDetails>
			<Divider />
			<AccordionActions>
				<Button
					size="small"
					onClick={() => {
						pageDocument.ref.delete()
					}}
				>
					Remove
				</Button>
				<Button
					size="small"
					component={Link}
					to={`/admin/edit/${encodeURIComponent(pageDocument.ref.path)}`}
				>
					Edit
				</Button>
				<Button
					size="small"
					component={Link}
					to={`/admin/create/${encodeURIComponent(pageDocument.ref.path)}`}
				>
					Create subpage
				</Button>
				<Button size="small" color="primary">
					Show page
				</Button>
			</AccordionActions>
		</Accordion>
	)
}

export default PagePreview

import React, {useState} from 'react'
import {
	FilledInput,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
} from '@material-ui/core'
import {Cancel} from '@material-ui/icons'
import {isValidCDEmail} from '../utils'

interface Props {
	className?: string
	value: string
	onChange: (value: string) => void
	onCancel: () => void
}

function StringListItem({
	className,
	value,
	onChange,
	onCancel,
}: Props): JSX.Element {
	const [errorMessage, setErrorMessage] = useState('')

	function checkForErrors(val: string) {
		setErrorMessage(
			isValidCDEmail(val)
				? ''
				: 'Value must be email with creativedock.cz/.com domain'
		)
	}

	return (
		<FormControl
			className={className}
			margin="normal"
			variant="filled"
			fullWidth
			error={!!errorMessage}
		>
			<InputLabel>Email</InputLabel>
			<FilledInput
				value={value}
				onChange={(e) => {
					onChange(e.target.value)
					if (errorMessage) {
						checkForErrors(e.target.value)
					}
				}}
				onBlur={() => checkForErrors(value)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={onCancel}
						>
							<Cancel />
						</IconButton>
					</InputAdornment>
				}
			/>
			<FormHelperText>
				{errorMessage ||
					'Email address of user that should have access to this page'}
			</FormHelperText>
		</FormControl>
	)
}

export default StringListItem

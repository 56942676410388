import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
	apiKey: 'AIzaSyA7JTsgk5gZKEeaVj7dE40_L1E25Y3nfuc',
	authDomain: 'cd-finance-firebase-reports.firebaseapp.com',
	projectId: 'cd-finance-firebase-reports',
	storageBucket: 'cd-finance-firebase-reports.appspot.com',
	messagingSenderId: '944811253186',
	appId: '1:944811253186:web:1a7c4742534b6d1d068a5b',
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const microsoftAuthProvider = new firebase.auth.OAuthProvider(
	'microsoft.com'
)
microsoftAuthProvider.setCustomParameters({
	tenant: '725a51d9-b0bb-4955-98ff-eb13e4550994',
})

export const firestore = firebase.firestore()

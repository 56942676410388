import React, {useEffect} from 'react'
import {Route, Switch, useHistory} from 'react-router-dom'
import OverviewPage from './components/OverviewPage'
import EditPage from './components/EditPage'
import CreatePage from './components/CreatePage'
import AdminListPage from './components/AdminListPage'
import {useUser} from '../AuthProvider'

function AdminPage(): JSX.Element {
	const user = useUser()
	const {replace} = useHistory()

	useEffect(() => {
		if (user.isAdmin === false) {
			replace('/')
		}
	}, [user.isAdmin, replace])
	return (
		<Switch>
			<Route path="/admin/admins">
				<AdminListPage />
			</Route>
			<Route path="/admin/edit/:pagePath">
				<EditPage />
			</Route>
			<Route path={['/admin/create/:parentPath', '/admin/create']}>
				<CreatePage />
			</Route>
			<Route path="/admin" exact>
				<OverviewPage />
			</Route>
		</Switch>
	)
}

export default AdminPage

import React, {useState} from 'react'
import clsx from 'clsx'
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Container,
	createStyles,
	Link as MuiLink,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {useObservable} from 'rxjs-hooks'
import {collection} from 'rxfire/firestore'
import {useSnackbar} from 'notistack'
import {firestore} from '../../../firebase'
import {isValidCDEmail} from '../utils'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		list: {
			margin: theme.spacing(2, 0, -2),
			'& > *': {
				margin: theme.spacing(2, 0),
			},
		},
	})
)

interface Props {
	className?: string
}

function AdminListPage({className}: Props): JSX.Element {
	const {enqueueSnackbar} = useSnackbar()
	const [emailToAdd, setEmailToAdd] = useState('')
	const admins = useObservable(() =>
		collection(firestore.collection('/admins'))
	)

	const emailToAddIsValid = isValidCDEmail(emailToAdd)

	function addUser() {
		if (!emailToAddIsValid) {
			enqueueSnackbar(
				'Email is not in correct format. Must be from creativedock domain',
				{variant: 'error'}
			)
			return
		}
		firestore
			.doc(`/admins/${emailToAdd}`)
			.set({})
			.then(() => {
				enqueueSnackbar(`${emailToAdd} added to admin list`, {
					variant: 'success',
				})
				setEmailToAdd('')
			})
			.catch(() => {
				enqueueSnackbar('Error while adding user to admin list', {
					variant: 'error',
				})
			})
	}

	const classes = useStyles()
	return (
		<Container maxWidth="md" className={clsx(classes.root, className)}>
			<MuiLink component={Link} to="/admin">
				Back
			</MuiLink>
			<Typography variant="h1"> Admins</Typography>
			<Typography>Here you can add and remove admin users</Typography>
			<div>
				<TextField
					label="Email of user you want to add"
					helperText="Email from createivedock.cz/com domain"
					margin="normal"
					variant="filled"
					value={emailToAdd}
					onChange={(e) => setEmailToAdd(e.target.value)}
				/>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						addUser()
					}}
				>
					<Button onClick={addUser} variant="contained" color="primary">
						Add user
					</Button>
				</form>
			</div>

			{admins && (
				<div className={classes.list}>
					{admins.map((one) => (
						<Card key={one.id}>
							<CardContent>{one.id}</CardContent>
							<CardActions>
								<Button
									onClick={() => {
										const removedAdmin = one.id
										one.ref
											.delete()
											.then(() => {
												enqueueSnackbar(
													`${removedAdmin} was removed from admins list`,
													{variant: 'success'}
												)
											})
											.catch(() => {
												enqueueSnackbar(
													'Error while removing user from admin list',
													{variant: 'error'}
												)
											})
									}}
									size="small"
								>
									Remove
								</Button>
							</CardActions>
						</Card>
					))}
				</div>
			)}
		</Container>
	)
}

export default AdminListPage

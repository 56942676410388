import React from 'react'
import clsx from 'clsx'
import {
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {Page} from '../../../models/Page'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		margin: {
			marginBottom: theme.spacing(4),
		},
		iframe: {
			width: '100%',
			height: '50vw',
		},
	})
)

interface Props {
	className?: string
	page: Page
}

function PageView({className, page}: Props): JSX.Element {
	const classes = useStyles()
	return (
		<div className={clsx(classes.root, className)}>
			<Container maxWidth="md">
				<Typography variant="h1" className={classes.margin}>
					{page.title}
				</Typography>
			</Container>
			{page.iframes.map((iframe) => (
				<div key={iframe.url}>
					<Container className={classes.margin} maxWidth="md">
						<Typography variant="h3">{iframe.title}</Typography>
					</Container>
					<iframe
						className={clsx(classes.iframe, classes.margin)}
						title={iframe.title}
						src={iframe.url}
					/>
				</div>
			))}
		</div>
	)
}

export default PageView

import {Page} from '../../models/Page'

// eslint-disable-next-line import/prefer-default-export
export function getEmptyPage(): Page {
	return {
		id: '',
		title: '',
		contentMd: '',
		iframes: [
			{
				title: '',
				url: '',
				textMd: '',
			},
		],
		usersWithAccess: [''],
		usersCanAccessAllSubpages: false,
	}
}

export function isValidCDEmail(email: string): boolean {
	return /.+@creativedock(\.cz|\.com)$/.test(email)
}

export function normalizePageData(page: Page): Page {
	return {
		...page,
		usersWithAccess: page.usersWithAccess
			.map((one) => one.trim())
			.filter(Boolean),
		iframes: page.iframes
			.map((one) => ({
				...one,
				url: one.url.trim(),
				title: one.title.trim(),
				textMd: one.textMd.trim(),
			}))
			.filter((one) => one.url),
		title: page.title.trim(),
		contentMd: page.contentMd.trim(),
		usersCanAccessAllSubpages: Boolean(page.usersCanAccessAllSubpages),
	}
}

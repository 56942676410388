import {useObservable} from 'rxjs-hooks'
import {map, switchMap} from 'rxjs/operators'
import {collection as collection$, docData} from 'rxfire/firestore'
import {combineLatest} from 'rxjs'
import firebase from 'firebase'
import {firestore} from '../../firebase'
import {useUser} from '../AuthProvider'
import {Page} from '../../models/Page'

type DocumentReference = firebase.firestore.DocumentReference

export interface PagePreview {
	title: string
	path: string
}

export function getParentsId(collectionPath: string): Array<DocumentReference> {
	const collection = firestore.collection(collectionPath)
	let current = collection.parent
	if (!current) return []

	const ids = [current]

	while (current.parent.parent) {
		ids.unshift(current.parent.parent)
		current = current.parent.parent
	}

	return ids
}

export function useSubpages(
	collectionPath: string
): PagePreview[] | null | undefined {
	const user = useUser()

	return useObservable<PagePreview[] | undefined | null, [string]>(
		(_, path$) =>
			path$.pipe(
				switchMap(([path]) => {
					const parentsIds = getParentsId(firestore.collection(path).path)

					return combineLatest([
						collection$(firestore.collection(path)),
						...parentsIds.map((one) => docData<Page>(one)),
					])
				}),
				// Filter out those current user can not access
				map(([collection, ...parents]) => {
					const hasAccessToEverything = parents.some((parent) => {
						return (
							parent.usersWithAccess.includes(user.email) &&
							parent.usersCanAccessAllSubpages
						)
					})

					if (hasAccessToEverything || user.isAdmin) return collection

					return collection.filter((one) =>
						one.data().usersWithAccess.includes(user.email)
					)
				}),
				map((collection) =>
					collection.map((one) => ({
						title: one.data().title as string,
						path: one.ref.path,
					}))
				)
			),
		null,
		[collectionPath]
	)
}

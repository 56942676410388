import React, {useContext} from 'react'
import firebase from 'firebase'
import {user as user$} from 'rxfire/auth'
import {doc as doc$} from 'rxfire/firestore'
import {
	catchError,
	combineLatestWith,
	filter,
	map,
	switchMap,
	tap,
} from 'rxjs/operators'
import {useObservable} from 'rxjs-hooks'
import {Observable, of, throwError} from 'rxjs'
import {auth, firestore} from '../firebase'
import LoginPage from './LoginPage'

function isAdmin$(email: string): Observable<boolean> {
	const adminRef = firestore.doc(`/admins/${email}`)

	return doc$(adminRef).pipe(
		map((doc) => doc.exists),
		catchError(() => of(false))
	)
}

function userData$(user: firebase.User) {
	if (!user) return of(null)
	if (!user.email) return throwError(() => new Error('No email'))

	const userDoc = firestore.doc(`/users/${user.email}`)
	return doc$(userDoc).pipe(
		tap((doc) => {
			if (!doc.exists) userDoc.set({email: user.email})
		}),
		filter((doc) => doc.exists),
		combineLatestWith(isAdmin$(user.email)),
		map(([doc, isAdmin]) => ({
			email: doc.data()?.email || 'shouldNotHappen',
			isAdmin,
		}))
	)
}

interface User {
	email: string
	isAdmin: boolean
}

const userContext = React.createContext({
	email: '',
	isAdmin: false,
})

function LoginProvider({children}: {children: React.ReactNode}): JSX.Element {
	const userWithData = useObservable<User | null | 'loading'>(
		() =>
			user$(auth).pipe(
				switchMap((user) => {
					return userData$(user)
				})
			),
		'loading'
	)
	if (userWithData === 'loading') return <div>Loading user...</div>
	if (userWithData === null) return <LoginPage />

	return (
		<userContext.Provider value={userWithData}>{children}</userContext.Provider>
	)
}

export default LoginProvider

export function useUser(): User {
	return useContext(userContext)
}

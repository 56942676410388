import React from 'react'
import clsx from 'clsx'
import {
	Breadcrumbs as MuiBreadcrumbs,
	Container,
	createStyles,
	Link as MuiLink,
	makeStyles,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {combineLatest} from 'rxjs'
import {useObservable} from 'rxjs-hooks'
import {map, switchMap} from 'rxjs/operators'
import {doc} from 'rxfire/firestore'
import {firestore} from '../../../firebase'
import {getParentsId} from '../db'

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
	})
)

interface Props {
	className?: string
	path: string
}

function useBreadcrumbs(docPath: string) {
	return useObservable<{title: string; path: string}[], [string]>(
		(_, path$) => {
			return path$.pipe(
				switchMap(([path]) => {
					const thisDocument = firestore.doc(path)
					const parents = getParentsId(thisDocument.parent.path)

					return combineLatest([
						...parents.map((parentDoc) => doc(parentDoc)),
						doc(thisDocument),
					])
				}),
				map(
					(pages) =>
						pages
							.map((page) => ({
								title: page.data()?.title,
								path: page.ref.path,
							}))
							.filter((page) => page.title) as {title: string; path: string}[]
				)
			)
		},
		[],
		[docPath]
	)
}

function Breadcrumbs({className, path}: Props): JSX.Element | null {
	const classes = useStyles()

	const breadcrumbs = useBreadcrumbs(path)

	return (
		<Container maxWidth="md" className={clsx(classes.root, className)}>
			<MuiBreadcrumbs>
				{breadcrumbs.map((one, i) => (
					<MuiLink
						key={one.path}
						component={Link}
						color={i === breadcrumbs.length - 1 ? 'textPrimary' : 'inherit'}
						to={`/browse/${encodeURIComponent(one.path)}`}
					>
						{one.title}
					</MuiLink>
				))}
			</MuiBreadcrumbs>
		</Container>
	)
}

export default Breadcrumbs

import React from 'react'
import clsx from 'clsx'
import {
	createStyles,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {Link} from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {useSubpages} from '../db'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		margin: {
			marginBottom: theme.spacing(4),
		},
		marginSmaller: {
			marginBottom: theme.spacing(1),
		},
	})
)

interface Props {
	className?: string
	collectionPath: string
	hideTitle?: boolean
}

function ListOfPages({
	className,
	hideTitle,
	collectionPath,
}: Props): JSX.Element {
	const classes = useStyles()

	const subpages = useSubpages(collectionPath)

	return (
		<List className={clsx(classes.root, className)}>
			{subpages && subpages.length === 0 && (
				<Typography>No subpages</Typography>
			)}
			{!hideTitle && subpages && subpages.length > 0 && (
				<>
					<Typography variant="h2" className={classes.marginSmaller}>
						Subpages
					</Typography>
					<Typography variant="body1" className={classes.margin}>
						Click on subpage below to view it
					</Typography>
				</>
			)}
			{subpages?.map((one) => (
				<ListItem
					key={one.path}
					button
					component={Link}
					to={`/browse/${encodeURIComponent(one.path)}`}
				>
					<ListItemText>{one.title}</ListItemText>
					<ArrowForwardIcon />
				</ListItem>
			))}
		</List>
	)
}

export default ListOfPages

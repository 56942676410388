import React from 'react'
import clsx from 'clsx'
import {Container, createStyles, makeStyles} from '@material-ui/core'
import {Redirect, useParams} from 'react-router-dom'
import {useObservable} from 'rxjs-hooks'
import {docData as docData$} from 'rxfire/firestore'
import {map, switchMap} from 'rxjs/operators'
import {firestore} from '../../../firebase'
import PageView from './PageView'
import {Page} from '../../../models/Page'
import ListOfPages from './ListOfPages'
import Breadcrumbs from './Breadcrumbs'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		breadcrumbs: {
			marginBottom: theme.spacing(1),
		},
	})
)

interface Props {
	className?: string
}

function PageWithSubpages({className}: Props): JSX.Element {
	const classes = useStyles()
	const path = `/${decodeURIComponent(useParams<{path: string}>().path)}`
	const page = useObservable<Page | 'notFound' | null, [string]>(
		(_, path$) =>
			path$.pipe(
				switchMap(([mPath]) => docData$<Page>(firestore.doc(mPath), 'id')),
				map((one) => {
					if (!one.title) return 'notFound'
					return one
				})
			),
		null,
		[path]
	)

	if (page === 'notFound') return <Redirect to="/404" />

	return (
		<div className={clsx(classes.root, className)}>
			<Breadcrumbs className={classes.breadcrumbs} path={path} />
			{page && <PageView page={page} />}
			<Container maxWidth="md">
				<ListOfPages collectionPath={`${path}/pages`} />
			</Container>
		</div>
	)
}

export default PageWithSubpages

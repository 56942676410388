import React from 'react'
import {
	Button,
	Card,
	CardContent,
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {auth, microsoftAuthProvider} from '../firebase'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		text: {
			'& > *': {
				marginBottom: theme.spacing(2),
			},
		},
		card: {
			marginTop: theme.spacing(8),
			paddingTop: theme.spacing(4),
			textAlign: 'center',
			'& > * > *': {
				marginBottom: theme.spacing(4),
			},
		},
	})
)

function LoginPage(): JSX.Element {
	const classes = useStyles()

	return (
		<Container maxWidth="sm" className={classes.root}>
			<Card className={classes.card}>
				<CardContent>
					<div className={classes.text}>
						<Typography variant="h2">Financial reports</Typography>
						<Typography variant="h4">CreativeDock</Typography>
						<Typography>
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							Login with CreativeDock's account using microsoft.com.
						</Typography>
					</div>
					<Button
						fullWidth
						color="primary"
						variant="contained"
						onClick={() => {
							auth.signInWithRedirect(microsoftAuthProvider)
						}}
					>
						Login
					</Button>
				</CardContent>
			</Card>
		</Container>
	)
}

export default LoginPage

import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import AdminPage from '../AdminPage'
import TopBar from './components/TopBar'
import BrowsePage from '../BrowsePage'
import ScrollToTop from '../ScrollToTop'
import SafariWarningOverlay from './components/SafariWarningOverlay'

const useStyles = makeStyles((theme) =>
	createStyles({
		appBar: {
			marginBottom: theme.spacing(4),
		},
		insideContainer: {
			paddingBottom: theme.spacing(8),
		},
	})
)

function InsidePage(): JSX.Element {
	const classes = useStyles()

	return (
		<BrowserRouter>
			<ScrollToTop />
			<SafariWarningOverlay />
			<div className={classes.insideContainer}>
				<TopBar className={classes.appBar} />

				<Switch>
					<Route path="/admin">
						<AdminPage />
					</Route>
					<Route path={['/browse/:path', '/']}>
						<BrowsePage />
					</Route>
				</Switch>
			</div>
		</BrowserRouter>
	)
}

export default InsidePage

import React from 'react'
import {
	Button,
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {useObservable} from 'rxjs-hooks'
import {collection as collection$} from 'rxfire/firestore'
import {Link} from 'react-router-dom'
import {firestore} from '../../../firebase'
import PagePreview from './PagePreview'

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {},
		createButton: {
			margin: theme.spacing(2, 0),
		},
		buttons: {
			margin: theme.spacing(1, -1, 2),
			'& > *': {
				margin: theme.spacing(0, 1),
			},
		},
	})
)
function OverviewPage(): JSX.Element {
	const classes = useStyles()

	const pages = useObservable(() => collection$(firestore.collection('/pages')))

	return (
		<Container maxWidth="md" className={classes.root}>
			<Typography variant="h1">Admin section</Typography>
			<Typography>All changes made here will be visible to users.</Typography>
			<div className={classes.buttons}>
				<Button
					className={classes.createButton}
					component={Link}
					to="/admin/create"
					variant="contained"
					color="primary"
				>
					Create new Page
				</Button>
				<Button
					component={Link}
					to="/admin/admins"
					variant="contained"
					color="primary"
				>
					Manage admins
				</Button>
			</div>
			<div>
				{pages?.map((one) => (
					<PagePreview key={one.ref.path} pageDocument={one} />
				))}
			</div>
		</Container>
	)
}

export default OverviewPage

import React from 'react'
import clsx from 'clsx'
import {Button, createStyles, makeStyles, TextField} from '@material-ui/core'
import {IframeContent} from '../../../models/Page'

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
	})
)

interface Props {
	className?: string
	value: IframeContent
	onChange: (value: IframeContent) => void
	onCancel: () => void
}

function IframesListItem({
	className,
	value,
	onChange,
	onCancel,
}: Props): JSX.Element {
	const classes = useStyles()
	return (
		<div className={clsx(classes.root, className)}>
			<TextField
				label="Title"
				helperText="Will be displayed above the iframe"
				fullWidth
				margin="normal"
				variant="filled"
				value={value.title}
				onChange={(e) => onChange({...value, title: e.target.value})}
			/>
			<TextField
				label="Iframe link"
				helperText="Iframe url copied from powerBI"
				fullWidth
				margin="normal"
				variant="filled"
				value={value.url}
				onChange={(e) => onChange({...value, url: e.target.value})}
			/>
			<Button variant="outlined" onClick={onCancel}>
				Remove
			</Button>
		</div>
	)
}

export default IframesListItem

import React, {useCallback, useEffect, useState} from 'react'
import clsx from 'clsx'
import {
	Button,
	CircularProgress,
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {useParams, useHistory} from 'react-router-dom'
import {docData as docData$} from 'rxfire/firestore'
import {useObservable} from 'rxjs-hooks'
import {first, map} from 'rxjs/operators'
import {useAsync} from 'react-async'
import {useSnackbar} from 'notistack'
import {firestore} from '../../../firebase'
import {Page} from '../../../models/Page'
import PageForm from './PageForm'
import {getEmptyPage, isValidCDEmail} from '../utils'

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
	})
)

interface Props {
	className?: string
}

function EditPage({className}: Props): JSX.Element {
	const classes = useStyles()
	const history = useHistory()
	const {enqueueSnackbar} = useSnackbar()
	const [pageData, setPageData] = useState<Page | undefined>(undefined)
	const documentRef = firestore.doc(
		`/${decodeURIComponent(useParams<{pagePath: string}>().pagePath)}`
	)
	const documentData = useObservable(
		() =>
			docData$<Page>(documentRef, 'id')
				// Take only the first one. We dont want this to be changed while user is editing it
				.pipe(
					first(),
					// Make sure to update accordningly when we add new page
					map((val) => ({...getEmptyPage(), ...val}))
				),
		undefined
	)

	useEffect(() => {
		setPageData(documentData)
	}, [documentData, setPageData])

	const submitTask = useAsync({
		deferFn: useCallback(async () => {
			if (!pageData) return
			if (
				pageData.usersWithAccess
					.filter(Boolean)
					.some((one) => !isValidCDEmail(one))
			) {
				enqueueSnackbar(
					'Some of listed emails are not emails from creativedock domain.',
					{variant: 'error'}
				)
				return
			}

			await documentRef.set(pageData)
			history.push(`/browse/${encodeURIComponent(documentRef.path)}`)
			enqueueSnackbar('Page saved', {variant: 'success'})
		}, [pageData, documentRef, history, enqueueSnackbar]),
	})

	return (
		<Container maxWidth="md" className={clsx(classes.root, className)}>
			<Typography variant="h1">Edit document</Typography>
			{pageData && (
				<div>
					<PageForm
						data={pageData}
						onChange={setPageData}
						onSubmit={submitTask.run}
					/>
					<Button
						disabled={submitTask.isLoading}
						color="primary"
						variant="contained"
						onClick={submitTask.run}
					>
						{submitTask.isLoading ? <CircularProgress /> : 'Submit'}
					</Button>
				</div>
			)}
			{!pageData && <CircularProgress />}
		</Container>
	)
}

export default EditPage

import {Page} from '../../models/Page'
import {firestore} from '../../firebase'
import {normalizePageData} from './utils'

async function getIdThatIsNotUsed(
	currentId: string,
	path: string | undefined
): Promise<string> {
	let doc = await firestore.doc(`${path}/${currentId}`).get()
	let number = 1

	while (doc.exists) {
		const newId = `${currentId}${number}`
		// eslint-disable-next-line no-await-in-loop
		doc = await firestore.doc(`${path}/${newId}`).get()
		number += 1
	}

	return doc.id
}

// eslint-disable-next-line import/prefer-default-export
export async function saveNewPage(
	document: Page,
	path = '/pages'
): Promise<string> {
	const id = await getIdThatIsNotUsed(document.id, path)
	const targetDocRef = firestore.doc(`${path}/${id}`)

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const {id: _, ...dataWithoutId} = normalizePageData(document)
	await targetDocRef.set(dataWithoutId)
	return targetDocRef.path
}
